var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"hero is-medium is-light has-gridlines"},[_c('div',{staticClass:"hero-body is-relative"},[_c('div',[_c('div',{staticClass:"columns is-multiline is-gapless"},[_c('div',{staticClass:"column is-full is-half-fullhd pt-6"},[_c('text-slide',{attrs:{"animated-text":_vm.heroText1}})],1),_c('div',{staticClass:"column is-full is-half-fullhd"},[_c('div',{class:{
                'px-6': ['fullhd'].includes(_vm.$mq),
                'py-6': ['mobile', 'tablet', 'desktop', 'widescreen'].includes(_vm.$mq),
              }},[_c('div',{staticClass:"is-retro",style:(`${!['mobile', 'tablet', 'desktop', 'widescreen'].includes(_vm.$mq) && 'width: 80%'}`)},[_c('b-image',{attrs:{"src":require('@/assets/img/silicon-valley.png'),"ratio":"is4by3"}})],1)])])])])])]),(['widescreen', 'fullhd'].includes(_vm.$mq))?_c('div',{staticClass:"section-two"}):_vm._e(),_c('section',{staticClass:"hero is-light has-gridlines",class:['fullhd'].includes(_vm.$mq) ? 'is-large' : 'is-small'},[_c('div',{staticClass:"hero-body"},[_c('div',{staticClass:"columns is-multiline"},[_c('mq-layout',{staticClass:"column is-full",attrs:{"mq":['mobile', 'tablet', 'desktop', 'widescreen']}},[(['tablet', 'desktop'].includes(_vm.$mq))?_c('div',{staticClass:"section-two"}):_vm._e(),_c('text-slide',{attrs:{"animated-text":_vm.heroText2}})],1),_c('div',{staticClass:"column is-full is-half-tablet is-relative",class:{ 'is-offset-one-third': ['tablet', 'desktop'].includes(_vm.$mq) }},[_c('mq-layout',{staticClass:"py-6 my-6",attrs:{"mq":['tablet', 'desktop']}}),_c('div',{staticClass:"is-flex is-justify-content-flex-end is-relative",class:{
              'px-6': ['sidescreen', 'fullhd'].includes(_vm.$mq),
              'py-6': ['mobile', 'tablet', 'desktop'].includes(_vm.$mq),
            }},[(['tablet', 'desktop', 'widescreen', 'fullhd'].includes(_vm.$mq))?_c('canvas-3-d',{staticClass:"offset-canvas is-absolute",staticStyle:{"width":"800px","height":"800px"}}):_vm._e(),_c('div',{style:(`width: ${!['mobile', 'tablet', 'desktop'].includes(_vm.$mq) ? '400px' : '100%'}`)},[_c('div',{staticClass:"is-retro is-danger has-background-light px-4"},[_c('b-image',{attrs:{"src":require('@/assets/img/kevin-cutout.png'),"ratio":"is5by7"}})],1)])],1),(_vm.$mq === 'mobile')?[_c('div',{staticClass:"section-two"}),_c('canvas-3-d')]:_vm._e()],2),_c('mq-layout',{staticClass:"column is-half",attrs:{"mq":['fullhd']}},[_c('text-slide',{attrs:{"animated-text":_vm.heroText2}}),(_vm.$mq === 'tablet')?_c('div',{staticClass:"section-two"}):_vm._e()],1)],1),_c('div',{staticClass:"section-five"})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }