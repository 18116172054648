var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-6"},[_c('div',{attrs:{"id":`trigger_${_vm._uid}`}}),_c('div',{staticClass:"columns is-gapless"},[_c('div',{staticClass:"column is-half is-flex is-flex-direction-column is-justify-content-space-between"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(['widescreen', 'desktop', 'fullhd'].includes(_vm.$mq)),expression:"['widescreen', 'desktop', 'fullhd'].includes($mq)"}],staticClass:"weird-grid",class:_vm.experience.backgroundClass,attrs:{"id":`panel_${_vm._uid}`}}),_c('div',{staticStyle:{"width":"100%"}},[_c('div',{staticClass:"work-experience-section has-background-light has-text-black",attrs:{"id":`card_${_vm._uid}`}},[_c('div',{class:{}},[_c('div',{staticStyle:{"border-bottom":"2px solid black"}},[_c('div',{staticClass:"mx-3 p-3",staticStyle:{"border-left":"2px solid black"}},[_c('p',{staticClass:"title is-6 is-size-7-mobile has-text-black"},[_vm._v(" "+_vm._s(_vm.experience.company)+" ")])])]),_c('div',{class:{
                'is-flex is-justify-content-space-between': ['fullhd'].includes(_vm.$mq),
                'has-text-left': ['mobile', 'tablet', 'desktop', 'widescreen'].includes(_vm.$mq),
              }},[_c('div',{staticClass:"mx-3",class:{
                  'p-5': ['fullhd'].includes(_vm.$mq),
                  'px-1 py-3': ['mobile', 'tablet', 'desktop', 'widescreen'].includes(_vm.$mq),
                },staticStyle:{"border-left":"2px solid black"}},[_c('div',{staticClass:"mb-1",class:{
                    'ml-3': ['fullhd'].includes(_vm.$mq),
                  }},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"map-marker"}}),_vm._v(_vm._s(_vm.experience.location)+" ")],1),_c('div',{staticClass:"mb-1",class:{
                    'ml-3': ['fullhd'].includes(_vm.$mq),
                  }},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"calendar"}}),_vm._v(_vm._s(_vm.experience.time)+" ")],1),_c('div',{staticClass:"mb-1",class:{
                    'ml-3': ['fullhd'].includes(_vm.$mq),
                  }},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"clock-outline"}}),_vm._v(_vm._s(_vm.experience.timeModel)+" ")],1)])])])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(['widescreen', 'desktop', 'fullhd'].includes(_vm.$mq)),expression:"['widescreen', 'desktop', 'fullhd'].includes($mq)"}],staticClass:"column is-half"},[_c('div',{staticClass:"px-6"},[_c('b-image',{staticClass:"is-clipped is-tinted",class:_vm.experience.imageClass || '',staticStyle:{"z-index":"4"},attrs:{"src":_vm.experience.imageUrl,"id":`image_${_vm._uid}`}})],1)])]),_c('div',{attrs:{"id":`end-trigger_${_vm._uid}`}})])
}
var staticRenderFns = []

export { render, staticRenderFns }